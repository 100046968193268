import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { red, yellow, blue, green, grey } from "@material-ui/core/colors";

export const FamilyCard = (props) => {
  return (
    <Grid container justifyContent="center" alignItems="center" spacing={5}>
      {props.familyStatus.map((fstatus, index) => (
        <Grid key={index} item xs={4}>
          <Card key={fstatus["name"]}>
            <CardActionArea
              onClick={() => props.displayStatus(fstatus["family"])}
            >
              <CardContent>
                <Stack direction="row" spacing={28}>
                  <Typography gutterBottom variant="h5" component="div">
                    {fstatus["family"]}
                  </Typography>
                  <Chip
                    label={"Total: " + fstatus["total"]}
                    style={{ backgroundColor: "" }}
                    alignItems="flex-end"
                  />
                </Stack>
                <Stack direction="row" spacing={0.1}>
                  <Chip
                    label={"Faults: " + fstatus["Fault"]}
                    style={{ backgroundColor: red[400] }}
                  />
                  <Chip
                    label={"Warning: " + fstatus["Warning"]}
                    style={{ backgroundColor: yellow[400] }}
                  />
                  <Chip
                    label={"Notice: " + fstatus["Notice"]}
                    style={{ backgroundColor: blue[400] }}
                  />
                  <Chip
                    label={"Normal: " + fstatus["Normal"]}
                    style={{ backgroundColor: green[400] }}
                  />
                  <Chip
                    label={"Offline: " + fstatus["Offline"]}
                    style={{ backgroundColor: grey[400] }}
                  />
                </Stack>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
