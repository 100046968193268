import React from "react";
import { Card, CardActions } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { red, yellow, blue, green, grey } from "@material-ui/core/colors";
import { Chip } from "@material-ui/core";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const ChillerCard = (props) => {
  const navigate = useNavigate();
  const getclr = (status) => {
    if (status === "Fault") return red[400];
    if (status === "Warning") return yellow[400];
    if (status === "Notice") return blue[400];
    if (status === "Normal") return green[400];
    if (status === "Offline") return grey[400];
  };

  const handleClick = (card) => {
    // console.log(card);
    const csn = card["csns"];
    const family = card["family"];
    const platform = card["platform"];
    navigate("/plot", {
      state: { csn: csn, family: family, platform: platform },
    });

    // console.log(props.chillerStatus[e]);
  };

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      {props.chillerStatus.map((cstatus, index) => (
        <Grid key={index} item xs={3}>
          <Card style={{ backgroundColor: getclr(cstatus["status"]) }}>
            <CardContent>
              <Typography variant="h6" component="div">
                {cstatus["platform"]} | {cstatus["family"]}
              </Typography>
              <Typography variant="h6" component="div">
                {cstatus["csns"]}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {" Start_date: " + cstatus["start_date"]}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {"Title: " + cstatus["title"]}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {"SR: " + cstatus["Service_Recommendation"]}
              </Typography>
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Chip
                  label="Plot"
                  icon={<SsidChartIcon />}
                  color="default"
                  onClick={() => handleClick(cstatus)}
                />
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
