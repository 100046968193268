import React from "react";
import Plot from "react-plotly.js";

export const DataPlot = (props) => {
  var temp = {
    width: "1100",
    height: "900",
    title: props.name,
    showlegend: true,
    xaxis: {
      title: {
        text: "Date",
      },
    },
    yaxis: {
      title: {
        text: props.y_axis,
      },
    },
    shapes: props.shapes,
    legend: { orientation: "h" },
    // shapes: [
    //   {
    //     type: "line",
    //     xref: "paper",
    //     x0: 0,
    //     y0: 1.2,
    //     x1: 1,
    //     y1: 1.2,
    //     line: {
    //       color: "orange",
    //       width: 4,
    //       dash: "dash",
    //     },
    //   },
    // ],
  };

  return (
    <Plot
      data={props.data}
      layout={temp}
      style={{ width: "100%", height: "100%" }}
    />
  );
};
