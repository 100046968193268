import * as React from 'react';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import CircularProgress from '@mui/joy/CircularProgress';
import Typography from '@mui/joy/Typography';
import SvgIcon from '@mui/joy/SvgIcon';
import { Grid } from "@material-ui/core";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Divider from '@mui/material/Divider';
import AcUnitTwoToneIcon from '@mui/icons-material/AcUnitTwoTone';
import NearbyErrorIcon from '@mui/icons-material/NearbyError';
import WarningIcon from '@mui/icons-material/Warning';
import InsightsIcon from '@mui/icons-material/Insights';
import TaskIcon from '@mui/icons-material/Task';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';

const CardInvertedColors = (props) => {
  // const familydataarray = props.summdata.data.familydata
  // const lastElement = familydataarray[familydataarray.length - 1];
  const keysArray = Object.keys(props.summdata.data.familydata);
  const lastRecKey = keysArray[keysArray.length - 1];
  const lastRecdata = props.summdata.data.familydata[lastRecKey];

  const [awsLink, setawsLink] = React.useState('');
  const handleopenlink = (param) => {
    window.open(param, '_blank');
  };

  const handleExportFile = (param) => {
    props.export_file(param);
  };

  const calculatePercentage = (realValue) => {
    const totalvalue = lastRecdata.totalunits;
    if (totalvalue === 0) return 0; // Handle division by zero
    if (realValue === 0) return 0; // Handle division by zero

    const percentage = (realValue / totalvalue) * 100;
    return parseFloat(percentage).toFixed(1); // Limit to two decimal places
  };

  return (
    <div>

      <Grid container rowSpacing={3} spacing={3} sx={{
        '@media screen and (max-width: 100%)': {
          // Define styles for default zoom level (100%)
          // You can adjust the styles based on your requirements
        },
        '@media screen and (max-width: 75%)': {
          // Define styles for 75% zoom level
          // You can adjust the styles based on your requirements
          // For example, decrease the width of the cards
          '& .cardContainer': {
            width: '12rem',
          },
        },
        '@media screen and (max-width: 50%)': {
          // Define styles for 50% zoom level
          // You can adjust the styles based on your requirements
          // For example, decrease the width of the cards even further
          '& .cardContainer': {
            width: '10rem',
          },
        },
        // Add more media queries as needed
      }}>
        <Grid style={{ paddingTop: "0rem" }} container item spacing={3}>

          <Card style={{ marginBottom: '1rem', marginRight: '1rem', width: "15rem" }} variant="solid" color="primary" invertedColors>
            <CardContent orientation="horizontal">
              <AcUnitTwoToneIcon style={{ height: "3.5rem", width: "3.5rem" }}></AcUnitTwoToneIcon>
              <CardContent>
                <Typography level="h4">Total Units</Typography>
                <Typography style={{ paddingTop: "1.5rem" }} level="h2">{lastRecdata.totalunits}</Typography>
              </CardContent>
            </CardContent>
          </Card>

          <Card style={{ marginBottom: '1rem', marginRight: '1rem', width: "15rem" }} variant="solid" color="primary" invertedColors>
            <CardContent orientation="horizontal">
              <TaskIcon style={{ height: "3.5rem", width: "3.5rem" }}></TaskIcon>
              <CardContent>
                <Typography level="h4">Field Data Exists</Typography>
                <Typography style={{ paddingTop: "1.5rem" }} level="h2">{lastRecdata.fielddataexists}
                  <span style={{ fontWeight:"lighter",paddingLeft:"5px",fontSize: "1.4rem" }}>({calculatePercentage(lastRecdata.fielddataexists)}%)</span>
                </Typography>
              </CardContent>
            </CardContent>
          </Card>

          <Card style={{ marginBottom: '1rem', marginRight: '1rem', width: "15rem" }} variant="solid" color="primary" invertedColors>
            <CardContent orientation="horizontal">
              <InsightsIcon style={{ height: "3.5rem", width: "3.5rem" }}></InsightsIcon>
              <CardContent>
                <Typography level="h4">KPIS Generated</Typography>
                <Typography style={{ paddingTop: "1.5rem" }} level="h2">{lastRecdata.kpisgenerated}
                  <span style={{ fontWeight:"lighter",paddingLeft:"5px",fontSize: "1.4rem" }}>({calculatePercentage(lastRecdata.kpisgenerated)}%)</span>
                </Typography>
              </CardContent>
            </CardContent>
          </Card>

          <Card style={{ marginBottom: '1rem', marginRight: '1rem', width: "15rem" }} variant="solid" color="primary" invertedColors>
            <CardContent orientation="horizontal">
              <NearbyErrorIcon style={{ height: "3.5rem", width: "3.5rem" }}></NearbyErrorIcon>
              <CardContent>
                <Typography level="h4">KPIS Below Threshold</Typography>
                <Typography level="h2">{lastRecdata.kpisbelowthreshold}
                  <span style={{ fontWeight:"lighter",paddingLeft:"5px",fontSize: "1.4rem" }}>({calculatePercentage(lastRecdata.kpisbelowthreshold)}%)</span>
                </Typography>
              </CardContent>
            </CardContent>
          </Card>

          <Card style={{ marginBottom: '1rem', marginRight: '1rem', width: "15rem" }} variant="solid" color="primary" invertedColors>
            <CardContent orientation="horizontal">
              <WarningIcon style={{ height: "3.5rem", width: "3.5rem" }}></WarningIcon>
              <CardContent>
                <Typography level="h4">Alarm/Alerts Count</Typography>
                <Typography style={{ paddingTop: "1.5rem" }} level="h2">{lastRecdata.alertalarmcount}</Typography>
              </CardContent>
            </CardContent>
          </Card>

          <Card style={{ marginBottom: '1rem', marginRight: '1rem', border: "2px solid #0B6BCB", width: "15rem" }} variant="solid" color="" invertedColors>
            <CardContent orientation="horizontal">
              <EventRepeatIcon style={{ color: "#0b6bcb", height: "3.5rem", width: "3.5rem" }}></EventRepeatIcon>
              <CardContent>
                <Typography color="primary" level="h4">Last Updated</Typography>
                <Typography style={{ paddingTop: "1.5rem" }} color="primary" level="h2">{lastRecdata.lastupdateddate}</Typography>
              </CardContent>
            </CardContent>
          </Card>

        </Grid>
      </Grid>
      <Divider style={{ marginTop: '1.5rem', fontWeight: "500" }} >Download Summary</Divider>
      <Box style={{ marginTop: '1.5rem', gap: '1rem' }} sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <Button style={{ padding: '1.5rem 1rem 1.5rem 1rem', fontWeight: "700", width: "15rem", boxShadow: "rgb(0 51 118 / 50%) 3px 5px 10px 0px" }} variant="outlined" size="large" onClick={() => handleExportFile(lastRecdata.summaryfileloc)} >L1 FDD Summary</Button>
        <Button style={{ padding: '1.5rem 1rem 1.5rem 1rem', fontWeight: "700", width: "15rem", boxShadow: "rgb(0 51 118 / 50%) 3px 5px 10px 0px" }} variant="outlined" size="large" onClick={() => handleopenlink(lastRecdata.l1fddpath)}>Open L1FDD Files</Button>
        <Button style={{ padding: '1.5rem 1rem 1.5rem 1rem', fontWeight: "700", width: "15rem", boxShadow: "rgb(0 51 118 / 50%) 3px 5px 10px 0px" }} variant="outlined" size="large" onClick={() => handleopenlink(lastRecdata.rawdatapath)}>Open Raw Data Files</Button>
        <Button style={{ padding: '1.5rem 1rem 1.5rem 1rem', fontWeight: "700", width: "15rem", boxShadow: "rgb(0 51 118 / 50%) 3px 5px 10px 0px" }} variant="outlined" size="large" onClick={() => handleExportFile(lastRecdata.retrofitsummary)} disabled={lastRecdata.retrofitsummary === ""}>Retrofit Summary</Button>
        <Button style={{ padding: '1.5rem 1rem 1.5rem 1rem', fontWeight: "700", width: "15rem", boxShadow: "rgb(0 51 118 / 50%) 3px 5px 10px 0px" }} variant="outlined" size="large" disabled={lastRecdata.retrofitpath === ""} onClick={() => handleopenlink(lastRecdata.retrofitpath)}>Open Retrofit Files</Button>
      </Box>
      <Divider style={{ marginTop: '1.5rem', fontWeight: "500" }} >Previous Execution Data</Divider>

    </div>
  );
}

export default CardInvertedColors;