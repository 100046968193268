import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import IconButton from '@mui/joy/IconButton';
import Link from '@mui/joy/Link';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { visuallyHidden } from '@mui/utils';

const headCells = [
    { id: 'lastupdateddate', label: 'Date' },
    { id: 'totalunits', label: 'Total Units' },
    { id: 'fielddataexists', label: 'FieldData Exists' },
    { id: 'kpisgenerated', label: 'KPIs Generated' },
    // { id: 'kpisbelowthreshold', label: 'KPIs < Threshold' },
    // { id: 'alertalarmcount', label: 'Alert/Alarm' },
    { id: 'summaryfileloc', label: 'Summary File' },
];
{/* Add columns here for alertalarm and kpi<Threshold */ }
function createDataRow(lastupdateddate, totalunits, kpisgenerated, fielddataexists, summaryfileloc) {
    return { lastupdateddate, totalunits, kpisgenerated, fielddataexists, summaryfileloc };
}

const labelDisplayedRows = ({ from, to, count }) => `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;

const getComparator = (order, orderBy) => (a, b) => {
    const orderMultiplier = order === 'desc' ? 1 : -1;
    if (b[orderBy] < a[orderBy]) return -1 * orderMultiplier;
    if (b[orderBy] > a[orderBy]) return 1 * orderMultiplier;
    return 0;
};

function EnhancedTableHead({ order, orderBy, onRequestSort }) {
    return (
        <thead>
            <tr>
                {headCells.map(({ id, label }) => (
                    <th style={{
                        color: 'black',
                        textAlign: '-webkit-center',
                        fontSize: 'large',
                        backgroundColor: '#d0e8ff',
                    }}

                        key={id} aria-sort={orderBy === id ? (order === 'desc' ? 'descending' : 'ascending') : undefined}>
                        <Link
                            component="button"
                            onClick={() => onRequestSort(id)}
                            underline="none"
                            color="blue"
                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}
                        >
                            {label}
                            {orderBy === id && (
                                <ArrowDownwardIcon sx={{ transform: order === 'desc' ? 'rotate(0)' : 'rotate(180deg)', transition: '0.2s' }} />
                            )}
                        </Link>
                    </th>
                ))}
            </tr>
        </thead>
    );
}

EnhancedTableHead.propTypes = {
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    onRequestSort: PropTypes.func.isRequired,
};

const Datatable = ({ summdata, export_file }) => {
    const rows = React.useMemo(() => {
        const myData = summdata.data.familydata;
        {/* Add columns here for alertalarm and kpi<Threshold */ }
        return Object.values(myData).map(({ lastupdateddate, totalunits, fielddataexists, kpisgenerated, summaryfileloc }) =>
            createDataRow(lastupdateddate, totalunits, fielddataexists, kpisgenerated, summaryfileloc)).reverse();
    }, [summdata]);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('lastupdateddate');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleRequestSort = (property) => setOrder((prevOrder) => (orderBy === property && prevOrder === 'asc' ? 'desc' : 'asc'));
    const handleChangePage = (newPage) => setPage(newPage);
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    console.log("rows", rows)

    return (
        <Sheet variant="outlined" sx={{ width: '95%', boxShadow: 'sm', borderRadius: 'sm', mt: 1.5 }}>
            <Table sx={{ '--TableCell-headBackground': 'transparent', '--TableCell-selectedBackground': (theme) => theme.vars.palette.success.softBg }}>
                <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                <tbody>
                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                            <tr key={index} style={{ textAlign: 'center', fontSize: '1.1rem' }}>
                                {Object.keys(row).map((key, i) => (
                                    <td key={i} style={{ fontWeight: i === 0 ? 'bold' : 'normal', textAlign: 'center' }}>
                                        {i === 0 &&
                                            row.lastupdateddate
                                        }
                                        {i === 1 &&
                                            row.totalunits
                                        }
                                        {i === 2 &&
                                            row.kpisgenerated
                                        }
                                        {i === 3 &&
                                            row.fielddataexists
                                        }
                                        {i===4 && key === 'summaryfileloc' &&
                                            <Link
                                                onClick={() => export_file(row[key])}
                                                sx={{ cursor: 'pointer', color: '#0b6bcb', textDecoration: 'underline', transition: 'color 0.3s' }}
                                            >
                                                Download
                                            </Link>
                                        }
                                    </td>
                                ))}
                            </tr>
                        ))}
                </tbody>
                <tfoot>
                    <tr>
                        {/* should be 7 for alertalarm and kpi<Threshold */}
                        <td colSpan={5}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, p: 1 }}>
                                <FormControl size="sm" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                    <FormLabel>Rows per page:</FormLabel>
                                    <Select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
                                        {[5, 10, 25].map((val) => (
                                            <Option key={val} value={val}>{val}</Option>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Typography textAlign="center" sx={{ minWidth: 80 }}>
                                    {labelDisplayedRows({ from: page * rowsPerPage + 1, to: Math.min(rows.length, (page + 1) * rowsPerPage), count: rows.length })}
                                </Typography>
                                <Box>
                                    <IconButton size="sm" onClick={() => handleChangePage(page - 1)} disabled={page === 0}>
                                        <KeyboardArrowLeftIcon />
                                    </IconButton>
                                    <IconButton size="sm" onClick={() => handleChangePage(page + 1)} disabled={page >= Math.ceil(rows.length / rowsPerPage) - 1}>
                                        <KeyboardArrowRightIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </td>
                    </tr>
                </tfoot>
            </Table>
        </Sheet>
    );
};

Datatable.propTypes = {
    summdata: PropTypes.object.isRequired,
    export_file: PropTypes.func.isRequired,
};

export default Datatable;
