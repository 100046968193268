import React from "react";
import { FamilyCard } from "./FamilyCard";
import { API } from "./MyApi";
import { ChillerCard } from "./ChillerCard";
import { Box, Grid } from "@material-ui/core";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { MasterCard } from "./MasterCard";
import { saveAs } from "file-saver";

export const Status = () => {
  const [totalStatus, setTotalStatus] = React.useState([]);
  const [familyStatus, setFamilyStatus] = React.useState([]);
  const [chillerStatus, setChillerStatus] = React.useState([]);
  const [platform, setPlatform] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [productFamily, setProductFamily] = React.useState([]);
  const [csns, setcsns] = React.useState([]);

  React.useEffect(() => {
    get_total_status();
    // get_status_info();
  }, []);

  const get_info = async () => {
    setOpen(true);
    const data_info = await API.getData("/get_platform");
    if (data_info) {
      setPlatform(data_info["platform"]);
    }
    setOpen(false);
  };

  const get_productfamily = async (e) => {
    setOpen(true);
    const param = { platform: e };
    console.log(param);
    const data_info = await API.getData("/get_pf", param);
    if (data_info) {
      setProductFamily(data_info["productFamily"]);
    }
    setOpen(false);
  };

  const get_csns = async (pf, platform) => {
    setOpen(true);
    const param = { PF: pf, platform: platform };
    const csn_data = await API.getData("/get_csn", param);
    if (csn_data) {
      setcsns(csn_data["csns"]);
    }
    setOpen(false);
  };
  const get_total_status = async () => {
    setOpen(true);
    const data_info = await API.getData("/total_status");
    if (data_info) {
      setTotalStatus(data_info["stat"]);
    }
    setOpen(false);
    get_info();
  };

  const get_status_info = async () => {
    setOpen(true);
    const data_info = await API.getData("/get_pf_status");
    if (data_info) {
      setFamilyStatus(data_info["stat"]);
    }
    setOpen(false);
  };

  const get_flag_status = async (flag) => {
    setOpen(true);
    const param = { flag: flag };
    const data_info = await API.getData("/get_flag_status", param);
    if (data_info) {
      setFamilyStatus([]);
      setChillerStatus(data_info["stat"]);
    }
    console.log(flag);
    setOpen(false);
  };

  const get_filter_status = async (plat, family, chiller) => {
    setOpen(true);
    setChillerStatus([]);
    setFamilyStatus([]);
    const param = { platform: plat, PF: family, csn: chiller };
    const data_info = await API.getData("/get_filter_status", param);
    if (data_info) {
      setChillerStatus(data_info["cstat"]);
      setFamilyStatus(data_info["fstat"]);
    }
    setOpen(false);
  };

  const export_filter_status = async (plat, family, chiller) => {
    setOpen(true);

    const param = { platform: plat, PF: family, csn: chiller };
    const data_info = await API.getData("/export_filter_status", param);
    if (data_info) {
      const blob = new Blob([data_info], {
        type: "text/csv",
      });
      const name = "status.csv";
      saveAs(blob, name);
    }
    setOpen(false);
  };

  const export_so = async (platform, pf) => {
    setOpen(true);
    const payload = {
      PF: pf,
      platform: platform,
    };
    const data_info = await API.postData("/export_so", payload, "arraybuffer");
    if (data_info["Error"]) {
      console.log(data_info["Error"]);
      setOpen(false);
    } else {
      const blob = new Blob([data_info], {
        type: "application/zip",
      });
      const name = platform + "_" + pf + "_" + "SalesOpportunity_data.csv";
      saveAs(blob, name);
    }
    setOpen(false);
  };

  const displayStatus = async (pf) => {
    setOpen(true);
    const param = {
      PF: pf,
    };
    const csn_info = await API.getData("/get_csn_status", param);
    if (csn_info) {
      setChillerStatus(csn_info["stat"]);
    }
    setOpen(false);
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
      <MasterCard
        totalStatus={totalStatus}
        platform={platform}
        csns={csns}
        productFamily={productFamily}
        get_csns={get_csns}
        get_productfamily={get_productfamily}
        get_flag_status={get_flag_status}
        get_filter_status={get_filter_status}
        export_filter_status={export_filter_status}
        export_so={export_so}
      ></MasterCard>
      <FamilyCard
        familyStatus={familyStatus}
        displayStatus={displayStatus}
      ></FamilyCard>
      <Box style={{ marginTop: 40 }}>
        <ChillerCard chillerStatus={chillerStatus}></ChillerCard>
      </Box>
      <div style={{ marginTop: 20 }}></div>
    </div>
  );
};
