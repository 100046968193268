import React from 'react';
import Bar from './Components/Bar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Components/Home';
import About from './Components/About';
import Exit from './Components/Exit';
import { Box } from '@mui/system';
import { Status } from './Components/Status';
import Retro from './Components/Retro/Retro';
import { Container } from '@material-ui/core';
import Offplot from './Components/Offplot';

function App() {
  return (
    <>
      <BrowserRouter>
        <Container style={{padding:0}} maxWidth="xl">

          <Box sx={{ display: 'flex' }}>
            <Bar />
          </Box>

          <Routes>
            <Route exact path='/' element={<Retro />} />
            <Route exact path='/dt' element={<Status />} />
            <Route exact path='/plot' element={<Home />} />
            {/* <Route exact path='/offplot' element={<Offplot />} /> */}
            <Route exact path='/logout' element={<Exit />} />
          </Routes>
        </Container>
      </BrowserRouter >
    </>
  );
}

export default App;
