import React from "react";
import { Box } from "@mui/system";
import { Side } from "./Side";
import { useEffect } from "react";
import { DataPlot } from "./plot";
import { API } from "./MyApi";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom";

// const api = axios.create({
//   baseURL: "http://localhost:5000/",
// });

const Home = () => {
  const [platform, setPlatform] = React.useState([]);
  const [productFamily, setProductFamily] = React.useState([]);
  const [csns, setcsns] = React.useState([]);
  const [plotName, setPlotName] = React.useState("");
  const [finalData, setFinalData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [parameter, setParameter] = React.useState([]);

  const [selectedPlatform, setSelectedPlatform] = React.useState("");
  const [selectedPF, setSelectedPF] = React.useState("");
  const [selectedCSN, setSelectedCSN] = React.useState("");
  const [yAxis, setYaxis] = React.useState([]);

  const [shapes, setShapes] = React.useState([]);
  const { state } = useLocation();

  useEffect(() => {
    // console.log(props.location.state);
    get_info();
  }, []);

  const get_info = async () => {
    if (state !== null) {
      const c = state["csn"];
      const f = state["family"];
      const p = state["platform"];
      setSelectedPlatform(p);
      setSelectedPF(f);
      setSelectedCSN(c);
      get_data(p, c, f, true, true);
    } else {
      setOpen(true);
      const data_info = await API.getData("/get_platform");
      if (data_info) {
        setPlatform(data_info["platform"]);
      }
      setOpen(false);
    }
  };

  const get_productfamily = async (e) => {
    setOpen(true);
    const param = { platform: e };
    console.log(param);
    const data_info = await API.getData("/get_pf", param);
    if (data_info) {
      setProductFamily(data_info["productFamily"]);
    }
    setOpen(false);
  };

  const get_csns = async (pf, platform) => {
    setOpen(true);
    const param = { PF: pf, platform: platform };
    const csn_data = await API.getData("/get_csn", param);
    if (csn_data) {
      setcsns(csn_data["csns"]);
    }
    const para = await API.getData("/get_para", param);
    if (para) {
      setParameter(para["para"]);
    }
    setOpen(false);
  };

  const export_data = async (platform, csn, pf, prodEnv, excludeHtmlPlot) => {
    // console.log(type);
    setOpen(true);
    const payload = {
      PF: pf,
      csn: csn,
      platform: platform,
      prodEnv: prodEnv,
      ExcludeHtmlPlot: excludeHtmlPlot,
    };

    const data_info = await API.getData("/export_data", payload, "arraybuffer");
    if (data_info) {
      const blob = new Blob([data_info], {
        type: "application/zip",
      });
      const name = platform + "_" + pf + "_" + csn + "_data.zip";
      saveAs(blob, name);
    } else {
      setOpen(false);
    }
    setOpen(false);
  };

  // const export_so = async (platform, pf) => {
  //   setOpen(true);
  //   const payload = {
  //     PF: pf,
  //     platform: platform,
  //   };
  //   const data_info = await API.postData("/export_so", payload, "arraybuffer");
  //   if (data_info) {
  //     console.log("Done");
  //     const blob = new Blob([data_info], {
  //       type: "application/zip",
  //     });
  //     const name = "SalesOpportunity_data.csv";
  //     saveAs(blob, name);
  //   } else {
  //     setOpen(false);
  //   }
  //   setOpen(false);
  // };

  const get_data = async (
    platform,
    csn,
    pf,
    hideNotice,
    hideCSAlerts,
    prodEnv,
    hideVFDAlerts
  ) => {
    setOpen(true);
    // setPlotName(pf + "_" + csn);
    const payload = {
      PF: pf,
      csn: csn,
      platform: platform,
      hideNotice: hideNotice,
      hideCSAlerts: hideCSAlerts,
      prodEnv: prodEnv,
      hideVFDAlerts: hideVFDAlerts,
    };

    const res = await API.postData("/get_data", payload);
    if (res) {
      const total_data = [];
      const total_shape = [];
      const total_plot_names = [];
      const total_y_axis = [];
      Object.keys(res["data"]).map((key) => {
        const raw_data = res["data"][key]["kpi_data"];
        const alarm_data = res["data"][key]["alarm_data"];
        const vfd_alerts = res["data"][key]["vfd_alerts"];
        const threshold_data = res["data"][key]["thresh"];
        const cs_alerts = res["data"][key]["cs_alerts_data"];
        const name = res["data"][key]["name"];
        const single_para_data = [];
        const single_para_shape = [];
        total_plot_names.push(pf + "_" + csn + "_" + name);
        total_y_axis.push(name);

        const consolidated = res["data"][key]["consolidated"];

        if (consolidated) {
          Object.keys(consolidated).map((key) => {
            if ((key !== "Date") & (key !== "name")) {
              var tempkey1 = {
                x: consolidated["Date"],
                y: consolidated[key],
                mode: "lines",
                type: "scatter",
                name: key,
                connectgaps: true,
              };
              single_para_data.push(tempkey1);
            }
          });
        }
        // console.log(raw_data);
        if (raw_data) {
          Object.keys(raw_data).map((key) => {
            if (key.includes("_kpitrend")) {
              var tempkey2 = {
                x: raw_data["Date"],
                y: raw_data[key],
                mode: "lines",
                type: "scatter",
                name: key,
                connectgaps: true,
                marker: { color: "blue" },
              };
              single_para_data.push(tempkey2);
            }
            if (key.includes("_mean")) {
              var tempkey3 = {
                x: raw_data["Date"],
                y: raw_data[key],
                text: raw_data[name + "_numpoints"],
                mode: "markers",
                type: "scatter",
                name: "daily mean",
                error_y: {
                  type: "data",
                  array: raw_data[name + "_std"],
                  visible: true,
                  color: "black",
                  thickness: 0.2,
                },
                marker: {
                  color: "red",
                  size: 10,
                  symbol: "x",
                },
                hovertemplate:
                  "(<b>%{x}<b>," + "<b> %{y}<b>)" + "<b> Points: %{text}</b>",
              };
              single_para_data.push(tempkey3);
            }
          });
        }

        if (alarm_data && raw_data) {
          Object.keys(alarm_data).map((alarm) => {
            if (alarm_data[alarm]["type"] === "Warning") {
              var tempkey4 = {
                x: alarm_data[alarm]["x"],
                y: alarm_data[alarm]["y"],
                mode: "line",
                type: "scatter",
                name: alarm_data[alarm]["title"],
                text: [alarm_data[alarm]["title"]],
                marker: {
                  color: "orange",
                },
                line: {
                  width: 5,
                },
              };
              single_para_data.push(tempkey4);
            }
            if (alarm_data[alarm]["type"] === "Fault") {
              var tempkey5 = {
                x: alarm_data[alarm]["x"],
                y: alarm_data[alarm]["y"],
                mode: "line",
                type: "scatter",
                name: alarm_data[alarm]["title"],
                text: [alarm_data[alarm]["title"]],
                marker: {
                  color: "red",
                },
                line: {
                  width: 5,
                },
              };
              single_para_data.push(tempkey5);
            }
            if (alarm_data[alarm]["type"] === "Notice") {
              var tempkey6 = {
                x: alarm_data[alarm]["x"],
                y: alarm_data[alarm]["y"],
                mode: "line",
                type: "scatter",
                name: alarm_data[alarm]["title"],
                text: [alarm_data[alarm]["title"]],
                marker: {
                  color: "gray",
                },
                line: {
                  width: 5,
                },
              };
              single_para_data.push(tempkey6);
            }
          });
        }

        if (vfd_alerts && raw_data) {
          Object.keys(vfd_alerts).map((alarm) => {
            if (vfd_alerts[alarm]["type"] === "Warning") {
              var tempkey8 = {
                x: vfd_alerts[alarm]["x"],
                y: vfd_alerts[alarm]["y"],
                mode: "line",
                type: "scatter",
                name: "VFD " + vfd_alerts[alarm]["title"],
                text: [vfd_alerts[alarm]["title"]],
                marker: {
                  color: "rgb(217,175,107)",
                },
                line: {
                  dash: "dashdot",
                  width: 3,
                },
              };
              single_para_data.push(tempkey8);
            }
            if (vfd_alerts[alarm]["type"] === "Alarm") {
              var tempkey9 = {
                x: vfd_alerts[alarm]["x"],
                y: vfd_alerts[alarm]["y"],
                mode: "line",
                type: "scatter",
                name: "VFD " + vfd_alerts[alarm]["title"],
                text: [vfd_alerts[alarm]["title"]],
                marker: {
                  color: "rgb(231,63,116)",
                },
                line: {
                  dash: "dashdot",
                  width: 3,
                },
              };
              single_para_data.push(tempkey9);
            }
          });
        }

        if (cs_alerts && raw_data) {
          var symbols = [
            "circle",
            "square",
            "diamond",
            "cross",
            "x",
            "triangle-up",
            "triangle-down",
            "triangle-left",
            "triangle-right",
            "triangle-ne",
            "triangle-se",
            "triangle-sw",
            "triangle-nw",
            "pentagon",
            "hexagon",
            "hexagon2",
            "star",
            "hexagram",
            "star-triangle-up",
            "star-triangle-down",
            "star-square",
            "star-diamond",
            "diamond-tall",
            "diamond-wide",
            "hourglass",
            "bowtie",
            "circle-cross",
            "circle-x",
            "square-cross",
            "square-x",
            "diamond-cross",
            "diamond-x",
            "cross-thin",
            "x-thin",
            "asterisk",
            "hash",
            "y-up",
            "y-down",
            "y-left",
            "y-right",
            "arrow-up",
            "arrow-down",
            "arrow-left",
            "arrow-right",
            "arrow-bar-up",
            "arrow-bar-down",
            "arrow-bar-left",
            "arrow-bar-right",
            "arrow",
            "arrow-wide",
          ];
          Object.keys(cs_alerts).map((idx, key) => {
            var tempkey7 = {
              x: cs_alerts[key]["x_axis"],
              y: cs_alerts[key]["y_axis"],
              text: cs_alerts[key]["description"],
              name: cs_alerts[key]["description"][0],
              mode: "markers",
              type: "scatter",
              marker: {
                color: "yellow",
                size: 20,
                // opacity: 0.5,
                symbol: symbols[idx],
                line: {
                  color: "red",
                  width: 1,
                },
              },
              hovertemplate:
                "<b>Date=%{x}<b>" + "<b> <b>" + "<b>Desc=%{text}</b>",
            };
            single_para_data.push(tempkey7);
          });
        }

        if (threshold_data && raw_data && alarm_data) {
          Object.keys(threshold_data).map((thresh) => {
            var tempshape = {
              type: "line",
              xref: "paper",
              x0: 0,
              y0: threshold_data[thresh],
              x1: 1,
              y1: threshold_data[thresh],
              line: {
                color: "orange",
                width: 4,
                dash: "dash",
              },
            };
            single_para_shape.push(tempshape);
          });
        }
        // console.log(single_para_data);
        total_data.push(single_para_data);
        total_shape.push(single_para_shape);
      });
      setPlotName(total_plot_names);
      setShapes(total_shape);
      setFinalData(total_data);
      setYaxis(total_y_axis);

      // console.log(finalData);
    }
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
      <Side
        csns={csns}
        platform={platform}
        productFamily={productFamily}
        parameter={parameter}
        get_csns={get_csns}
        get_data={get_data}
        export_data={export_data}
        get_productfamily={get_productfamily}
        selectedPlatform={selectedPlatform}
        setSelectedPlatform={setSelectedPlatform}
        selectedPF={selectedPF}
        setSelectedPF={setSelectedPF}
        selectedCSN={selectedCSN}
        setSelectedCSN={setSelectedCSN}
      />
      <Box id="maybox" component="main" sx={{ flexGrow: 1, p: 3 }}>
        {finalData.map((val, idx) => (
          <div>
            <DataPlot
              data={val}
              name={plotName[idx]}
              shapes={shapes[idx]}
              y_axis={yAxis[idx]}
            ></DataPlot>
          </div>
        ))}
      </Box>
    </Box>
  );
};

export default Home;
