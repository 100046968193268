import React from "react";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import { Autocomplete, FormControlLabel, List, ListItem } from "@mui/material";
import { Box } from "@mui/material";
import { FormControl } from "@mui/material";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";

// import Autocomplete from "@mui/material";

const drawerWidth = 340;

export const Side = (props) => {
  // const [productFamily, setProductFamily] = React.useState("");
  // const [selectedPlatform, setSelectedPlatform] = React.useState("");
  // const [selectedPF, setSelectedPF] = React.useState("");
  // const [selectedCSN, setSelectedCSN] = React.useState("");
  const [hideNotice, setHideNotice] = React.useState(true);
  const [hideCSAlerts, setHideCSAlerts] = React.useState(true);
  const [hideVFDAlerts, setHideVFDAlerts] = React.useState(true);
  const [prodEnv, setProdEnv] = React.useState(true);
  const [excludeHtmlPlot, setExcludeHtmlPlot] = React.useState(true);

  // const parameters = ["kpitrend", "mean", "std", "numpoints"];

  const handleChangePlatform = (value) => {
    props.setSelectedPlatform(value);
    props.setSelectedPF([]);
    props.setSelectedCSN([]);
    props.get_productfamily(value);
  };

  const handleChangeEnv = (value) => {
    props.setEnv(value);
    // props.setSelectedPlatform(value);
    // props.setSelectedPF([]);
    // props.setSelectedCSN([]);
    // props.get_productfamily(value);
  };

  const handleChangePF = (value) => {
    props.setSelectedPF(value);
    props.setSelectedCSN([]);
    props.get_csns(value, props.selectedPlatform);
  };

  const handleChangeCSN = (value) => {
    props.setSelectedCSN(value);
  };

  // const handleChangePT = (value) => {
  //   setParameterType(value);
  // };

  const handlePlot = (csn, pf) => {
    props.get_data(
      props.selectedPlatform,
      props.selectedCSN,
      props.selectedPF,
      hideNotice,
      hideCSAlerts,
      prodEnv,
      hideVFDAlerts
    );
  };

  const handleExport = (csn, pf) => {
    props.export_data(
      props.selectedPlatform,
      props.selectedCSN,
      props.selectedPF,
      prodEnv,
      excludeHtmlPlot
    );
  };

  const handleNotice = (e) => {
    setHideNotice(!hideNotice);
  };

  const handleEnv = (e) => {
    setProdEnv(!prodEnv);
  };

  const handleExcludeHtmlPlot = (e) => {
    setExcludeHtmlPlot(!excludeHtmlPlot);
  };

  const handleCSALerts = (e) => {
    setHideCSAlerts(!hideCSAlerts);
  };

  const handleVFDAlerts = (e) => {
    setHideVFDAlerts(!hideVFDAlerts);
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <List>
          <ListItem>
            <FormControlLabel
              control={
                <Switch value={prodEnv} onClick={handleEnv} defaultChecked />
              }
              labelPlacement="start"
              label="Prod Env"
              size="small"
            />
          </ListItem>
          <ListItem>
            <Autocomplete
              clearOnEscape
              id="PlatformSelect"
              options={props.platform}
              sx={{ width: 300 }}
              value={props.selectedPlatform}
              onChange={(event, newValue) => {
                handleChangePlatform(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Platform" />
              )}
            />
          </ListItem>
          <ListItem>
            <Autocomplete
              clearOnEscape
              id="PFselect"
              options={props.productFamily}
              sx={{ width: 300 }}
              value={props.selectedPF}
              onChange={(event, newValue) => {
                handleChangePF(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Product Family" />
              )}
            />
          </ListItem>

          <ListItem>
            <Autocomplete
              clearOnEscape
              id="csn_select"
              options={props.csns}
              sx={{ width: 300 }}
              value={props.selectedCSN}
              onChange={(event, newValue) => {
                handleChangeCSN(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="CSN" />}
            />
          </ListItem>
          <ListItem>
            <FormControlLabel
              control={
                <Checkbox
                  value={hideNotice}
                  onClick={handleNotice}
                  defaultChecked
                  size="small"
                />
              }
              label="Hide Notice"
            />
            <FormControlLabel
              control={
                <Checkbox
                  value={hideCSAlerts}
                  onClick={handleCSALerts}
                  defaultChecked
                  size="small"
                />
              }
              label="Hide CS Alerts"
            />
          </ListItem>

          <ListItem>
            <FormControlLabel
              control={
                <Checkbox
                  value={hideVFDAlerts}
                  onClick={handleVFDAlerts}
                  defaultChecked
                  size="small"
                />
              }
              label="Hide VFD Alerts"
            />
          </ListItem>

          <ListItem>
            <FormControl fullWidth>
              <Button variant="contained" onClick={handlePlot}>
                Plot
              </Button>
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControlLabel
              control={
                <Switch
                  value={excludeHtmlPlot}
                  onClick={handleExcludeHtmlPlot}
                  defaultChecked
                />
              }
              labelPlacement="start"
              label="Exclude HTML PLOT"
              size="small"
            />
          </ListItem>
          <ListItem>
            <FormControl fullWidth>
              <Button variant="contained" onClick={handleExport}>
                Export
              </Button>
            </FormControl>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};
