import React from "react";
import Card from "@material-ui/core/Card";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import { CardContent } from "@material-ui/core";
import { Stack } from "@mui/system";
import Chip from "@mui/material/Chip";
import { indigo } from "@material-ui/core/colors";

// import Autocomplete from "@mui/material";

export const StatusFilter = (props) => {
  const [selectedPlatform, setSelectedPlatform] = React.useState("");
  const [selectedPF, setSelectedPF] = React.useState("");
  const [selectedCSN, setSelectedCSN] = React.useState("");

  const handleChangePlatform = (value) => {
    setSelectedPlatform(value);
    setSelectedPF("");
    setSelectedCSN("");
    props.get_productfamily(value);
  };

  const handleChangePF = (value) => {
    setSelectedPF(value);
    setSelectedCSN("");
    props.get_csns(value, selectedPlatform);
  };

  const handleChangeCSN = (value) => {
    setSelectedCSN(value);
  };

  const handleClickSelect = (value) => {
    props.get_filter_status(selectedPlatform, selectedPF, selectedCSN);
  };

  const handleClickExport = (value) => {
    console.log("Export");
    props.export_filter_status(selectedPlatform, selectedPF, selectedCSN);
  };

  const handleClickExportSO = (value) => {
    props.export_so(selectedPlatform, selectedPF);
  };

  return (
    <Card>
      <CardContent>
        <Stack
          direction="row"
          spacing={2}
          marginBottom={0}
          alignItems="center"
          justifyContent="center"
        >
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            sx={{ width: 200 }}
            options={props.platform}
            value={selectedPlatform}
            onChange={(event, newValue) => {
              handleChangePlatform(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Platform" size="small" />
            )}
          />
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            sx={{ width: 200 }}
            options={props.productFamily}
            value={selectedPF}
            onChange={(event, newValue) => {
              handleChangePF(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Product Family" size="small" />
            )}
          />
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            sx={{ width: 200 }}
            options={props.csns}
            value={selectedCSN}
            onChange={(event, newValue) => {
              handleChangeCSN(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="CSN" size="small" />
            )}
          />
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          marginTop={0.5}
          justifyContent="flex-end"
        >
          {/* <Typography gutterBottom size="small" component="div">
            {"Select"}
          </Typography> */}
          <Chip
            label="Select"
            style={{ backgroundColor: indigo[400] }}
            onClick={handleClickSelect}
          />
          <Chip
            label="Export Status (csv)"
            style={{ backgroundColor: indigo[400] }}
            onClick={handleClickExport}
          />
          <Chip
            label="Export SO"
            style={{ backgroundColor: indigo[400] }}
            onClick={handleClickExportSO}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};
