import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import RefreshIcon from '@mui/icons-material/Refresh';
// import GraphCards from './GraphCards/GraphCards';
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { useEffect } from "react";
import { API } from "../MyApi"
import { Grid } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import CardInvertedColors from './Summarycard/Summarycards'
import Datatable from './Datatable/Datatable'
import Snackbar from '@mui/material/Snackbar';
import Loadcard from './Loadcard/Loadcard';
import MultiLoadcard from './Loadcard/MultiLoadcard';
import Modal from '@mui/material/Modal';
import { saveAs } from "file-saver";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';

const Retro = () => {

  const [open, setOpen] = React.useState(false);
  const [openLoadcard, setopenLoadcard] = React.useState(false);
  const [snackopen, setSnackopen] = React.useState(false);
  const [snackmsg, setSnackmsg] = React.useState(null);
  const [dwnldsnackopen, setDwnldsnackopen] = React.useState(false);
  const [dwnldsnackmsg, setDwnldsnackmsg] = React.useState(null);
  const [summdata, setSummdata] = React.useState('');
  const [unitdata, setUnitdata] = React.useState('');
  const [loadtext, setLoadtext] = React.useState('Load');
  const [selectedCSN, setSelectedCSN] = React.useState("");
  const [selectedCSNs, setSelectedCSNs] = useState([]);
  const [multiChiller, setMultiChiller] = useState(false);
  const [fmlyvalue, setFmlyvalue] = React.useState(0);
  const [family, setFamily] = React.useState("19XR");
  const [latestdate, setLatestdate] = React.useState("");
  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#2e1534',
    },
  });

  const handleMultiChillerChange = (event) => {
    setSelectedCSN(null);
    setSelectedCSNs([]);
    setMultiChiller(event.target.checked);
    if (!multiChiller){
      setLoadtext("Download FDD")
    }else{
      setLoadtext("Load")
    }
    
  };

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      color: 'rgba(0, 0, 0, 0.7)',
      '&.Mui-selected': {
        color: '#000',
      },
      '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
      },
    }),
  );

  const handleAutochillerChange = (event, value) => {
    setSelectedCSN(value);
  };

  const handleMultiAutochillerChange = (event, value) => {
    setSelectedCSNs(value);
    // console.log("setselectedMulti", selectedCSNs)
  };

  const handleOpenLoadcard = () => {
    if (multiChiller === false) {
      if (selectedCSN) {
        fetch_unit_data()

      } else {
        setSnackopen(true)
        setSnackmsg("Please select Chiller..")

      }
    }
    else {
      if (selectedCSNs.length>0) {
        // console.log("Getting Multi Units data",selectedCSNs)
        fetch_multi_unit_data(selectedCSNs);
        setSnackopen(true);
        setSnackmsg("Triggered "+ selectedCSNs.length +" Multi Chillers FDD Data Download || Results will be Mailed.")
        setSelectedCSNs([]);
      } else {
        setSnackopen(true)
        setSnackmsg("Please select Chillers..")

      }
    }
  }

  const handleCloseLoadcard = () => setopenLoadcard(false);

  const handlefamilyChange = (event, value) => {

    if (value === 0) {
      setFamily("19XR");
    }
    if (value === 1) {
      setFamily("19DV");
    }
    if (value === 2) {
      setFamily("Horizon")
    }
    if (value === 3) {
      setFamily("30KAV")
    }
    if (value === 4) {
      setFamily("30XA")
    }
    if (value === 5) {
      setFamily("30XV")
    }
    if (value === 6) {
      setFamily("30RB")
    }
    if (value === 7) {
      setFamily("30RA")
    }

    setFmlyvalue(value)
  };


  useEffect(() => {
    // console.log(props.location.state);
    get_summary_data();
    setSelectedCSN(null);
    setSelectedCSNs([]);
  }, [family]);

  const get_summary_data = async (e) => {
    setOpen(true);
    const param = { "family": family };
    console.log(param);
    const data_info = await API.getData("/retro_summary", param);
    // console.log("data_info", data_info)
    if (data_info) {
      setSummdata(data_info);
      const keysArray = Object.keys(data_info.data.familydata);
      const lastRecKey = keysArray[keysArray.length - 1];
      const lastRecdata = data_info.data.familydata[lastRecKey];
      setLatestdate(lastRecdata.lastupdateddate);
    }
    setOpen(false);
  };

  const handleRefreshClick = async () => {
    try {
      setSnackopen(true);
      setSnackmsg("Refreshing FDD Data...");

      await API.getData("/refresh_retro");

      setSnackmsg("Updating All Families Data in 15s...");

      await new Promise(resolve => setTimeout(resolve, 20000));

      get_summary_data();

      setSnackopen(true);
      setSnackmsg("FDD Data Refresh Successfull");
      console.log("Done");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleRefreshClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackopen(false);
    setSnackmsg(null)
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleRefreshClose}>
        X
      </Button>
    </React.Fragment>
  );

  const export_file = async (filekey) => {
    setOpen(true);
    const payload = { "filekey": filekey };
    try {
      const url = await API.getData("/export_retro_s3", payload);
      if (url) {
        console.log("Done");
        if (url.includes('.json')) {
          window.open(url, '_blank');
        } else {
          window.location.assign(url);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setOpen(false);
    }
  };

  const startDownloadZipInterval = async (unit, ZipFilename) => {
    const get_payload = {
      "type": "get_zip",
      "zipfilename": ZipFilename
    };
    const intervalId = setInterval(async () => {
      const resp = await API.postData("/download_unit_data", get_payload);
      if (resp.status === "done") {
        console.log('Download Completed');
        setDwnldsnackopen(false)
        setDwnldsnackmsg(null)
        setSnackopen(true)
        setSnackmsg(unit + " Results - Starting Download...")
        window.location.assign(resp.data);
        clearInterval(intervalId);
      }
      else {
        setDwnldsnackopen(true)
        setDwnldsnackmsg(unit + " - Processing Result files...")
      }
    }, 4000);
  };

  const export_data_retro = async (unit, filelist) => {
    try {
      const payload = {
        "type": "trigger_zip",
        "unit": unit,
        "filelist": filelist,
        "family": family
      };
      const resp = await API.postData("/download_unit_data", payload);
      console.log(resp)
      if (resp.statusCode === 200) {
        setDwnldsnackopen(true)
        setDwnldsnackmsg(unit + " - Download pending")
        startDownloadZipInterval(unit, resp.data);
      } else {
        setSnackopen(true)
        setSnackmsg("Technical issue at the Backend")
      }
    } catch (error) {
      console.error("Error during API call:", error);
      console.log("zip download failed");
      setSnackopen(true)
      setSnackmsg("Timeout Error || Please Retry Download")
    }
  };


  const fetch_unit_data = async (e) => {
    setOpen(true);
    const param = { "unit": selectedCSN, "family": family, "date": latestdate };
    const unit_info = await API.getData("/fetch_unit_data", param);
    console.log("unit_info", unit_info)
    if (unit_info) {
      setUnitdata(unit_info);
    }
    setOpen(false);
    setopenLoadcard(true);
  };

  const fetch_multi_unit_data = async (csns) => {
    setOpen(true);
    const param = { "units": csns, "family": family, "date": latestdate };
    console.log("params",param);
    await API.postData("/export_multi_unit_data", param);
    setOpen(false);
  };

  return (
    <div className="Retro">
      <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" paddingTop="1%">
        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <div className="buttonbox" style={{  marginLeft: "1rem", marginRight: "1rem", flex: "90%", boxShadow: "rgb(106 156 218) 3px 5px 10px 0px", display: "flex", flexDirection: "column", alignItems: "center" }}>
              {/* <div style={{ fontWeight: 900, width: "100%" }}>
                <StyledTabs value={pfvalue} onChange={handlepfChange} centered>
                  <StyledTab label="CARRIER SMART" />
                  <StyledTab label="BLUE EDGE" />
                </StyledTabs>
              </div> */}
              <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <div style={{ flex: "70%", width: "100%", fontWeight: 'bolder' }}>
                  <StyledTabs value={fmlyvalue} onChange={handlefamilyChange} aria-label="styled tabs example">
                    <StyledTab style={{ fontWeight: 'bolder' }} label="19XR" />
                    <StyledTab style={{ fontWeight: 'bolder' }} label="19DV" />
                    <StyledTab style={{ fontWeight: 'bolder' }} label="Horizon" />
                    <StyledTab style={{ fontWeight: 'bolder' }} label="30KAV" />
                    <StyledTab style={{ fontWeight: 'bolder' }} label="30XA" />
                    <StyledTab style={{ fontWeight: 'bolder' }} label="30XV" />
                    <StyledTab style={{ fontWeight: 'bolder' }} label="30RB" />
                    <StyledTab style={{ fontWeight: 'bolder' }} label="30RA" />
                  </StyledTabs>
                </div>

                <div style={{ paddingLeft: "1rem" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<RefreshIcon />}
                    onClick={handleRefreshClick}
                    style={{ padding: "1rem", position: "right" }}
                  >
                  </Button>
                </div>

                {!multiChiller && summdata && <div style={{ flex: "20%", marginLeft: "10px", margin: "1rem" }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={[...new Set(summdata?.data?.chillerdata)]}
                    sx={{ width: 300 }}
                    onChange={handleAutochillerChange}
                    isOptionEqualToValue={(option, value) => option === value || value === ""}
                    value={selectedCSN}
                    renderInput={(params) => <TextField {...params} label="Chillers" />}
                  />
                </div>}

                {multiChiller && summdata && <div style={{ flex: "20%", marginLeft: "10px", margin: "1rem" }}>
                  <Autocomplete
                    sx={{ width: "32rem" }}
                    multiple
                    size='small'
                    limitTags={2}
                    id="combo-box-demo"
                    options={[...new Set(summdata?.data?.chillerdata)]}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    value={selectedCSNs}
                    onChange={handleMultiAutochillerChange}
                    isOptionEqualToValue={(option, value) => option === value || value === ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Select Multiple Chillers"
                        placeholder="Units"
                      />
                    )}
                  />
                </div>}

                <div style={{ flex: "5%", marginLeft: "5px" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={multiChiller}
                        onChange={handleMultiChillerChange}
                        color="primary"
                      />
                    }
                    label="Multi Chiller"
                    labelPlacement="bottom"
                  />
                </div>

              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
          <Grid container spacing={10} direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <ButtonGroup class="PlotButtonGroupClass" style={{ borderRadius: "16px", boxShadow: "rgb(0 51 118) 3px 5px 10px 0px" }} variant="outlined" fullWidth="true" aria-label="large button group" size="large">
                <Button style={{ fontWeight: 900, borderRadius: "16px", backgroundColor: '#152c73', color: 'white', paddingTop: "25px", paddingBottom: "25px" }} onClick={handleOpenLoadcard} >{loadtext}</Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div style={{ paddingTop: "3%", paddingLeft: "3%" }}>

        <Backdrop
          sx={{ color: "black", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="primary" />
        </Backdrop>

        {summdata && <CardInvertedColors summdata={summdata} export_file={export_file}></CardInvertedColors>}
        {summdata && <Datatable summdata={summdata} export_file={export_file} ></Datatable>}

      </div>



      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackopen}
        autoHideDuration={5000}
        onClose={handleRefreshClose}
        message={snackmsg}
        action={action}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={dwnldsnackopen}
        onClose={handleRefreshClose}
        message={dwnldsnackmsg}
        action={action}
      ></Snackbar>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openLoadcard}
        onClose={handleCloseLoadcard}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >

        {multiChiller ? (
          <MultiLoadcard
            setSnackopen={setSnackopen}
            setSnackmsg={setSnackmsg}
            export_data_retro={export_data_retro}
            export_file={export_file}
            openLoadcard={openLoadcard}
            unitdata={unitdata}
            selectedCSNs={selectedCSNs}
          />
        ) : (
          <Loadcard
            setSnackopen={setSnackopen}
            setSnackmsg={setSnackmsg}
            export_data_retro={export_data_retro}
            export_file={export_file}
            openLoadcard={openLoadcard}
            unitdata={unitdata}
            selectedCSN={selectedCSN}
          />
        )}

      </Modal>


    </div>




  );
};

export default Retro;
