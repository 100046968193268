import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { red, yellow, blue, green, grey } from "@material-ui/core/colors";
import { StatusFilter } from "./StatusFilter";

export const MasterCard = (props) => {
  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid key={"total"} item xs={6}>
        <Card key={"total"}>
          <CardContent>
            <Stack direction="row" spacing={28}>
              <Typography gutterBottom variant="h5" component="div">
                {"Status"}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2} marginTop={0.5}>
              <Chip
                label={"Total: " + props.totalStatus["total"]}
                // onClick={() => props.get_flag_status("total")}
                style={{ backgroundColor: "" }}
                alignItems="flex-end"
              />
              <Chip
                label={"Faults: " + props.totalStatus["Fault"]}
                style={{ backgroundColor: red[400] }}
                onClick={() => props.get_flag_status("Fault")}
              />
              <Chip
                label={"Warning: " + props.totalStatus["Warning"]}
                style={{ backgroundColor: yellow[400] }}
                onClick={() => props.get_flag_status("Warning")}
              />
              <Chip
                label={"Notice: " + props.totalStatus["Notice"]}
                style={{ backgroundColor: blue[400] }}
                onClick={() => props.get_flag_status("Notice")}
              />
              <Chip
                label={"Normal: " + props.totalStatus["Normal"]}
                style={{ backgroundColor: green[400] }}
                onClick={() => props.get_flag_status("Normal")}
              />
              <Chip
                label={"Offline: " + props.totalStatus["Offline"]}
                style={{ backgroundColor: grey[400] }}
                onClick={() => props.get_flag_status("Offline")}
              />
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <StatusFilter
          platform={props.platform}
          totalStatus={props.totalStatus}
          csns={props.csns}
          productFamily={props.productFamily}
          get_csns={props.get_csns}
          get_productfamily={props.get_productfamily}
          get_filter_status={props.get_filter_status}
          export_filter_status={props.export_filter_status}
          export_so={props.export_so}
          // get_flag_status={get_flag_status}
        ></StatusFilter>
      </Grid>
    </Grid>
  );
};
