import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import logo from "../pics/logo.png";
import { Link } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";

function Bar(props) {
  return (
    <AppBar
      position="static"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      title={<img src={logo} />}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            component="img"
            sx={{
              height: 60,
            }}
            alt="Your logo."
            src={logo}
            px={2}
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            CHVAC ADVANCED ANALYTICS
          </Typography>
          <Box sx={{fontWeight:450, fontSize:"large" ,gap:3, flexGrow: 1, display:"flex",justifyContent:"flex-end"}}>
          <Link
              to="/"
              style={{
                textDecoration: "none",
                color: "inherit",
                margin: "5px",
                marginLeft:"10px",
                paddingTop:7
              }}
            >
              Home
            </Link>
            <Link
              to="/dt"
              style={{
                textDecoration: "none",
                color: "inherit",
                margin: "5px",
                paddingTop:7
              }}
            >
              DT
            </Link>
            <Link
              to="/plot"
              style={{
                textDecoration: "none",
                color: "inherit",
                margin: "5px",
                paddingTop:7
              }}
            >
              Plot
            </Link>
            {/* <Link
              to="/offplot"
              style={{
                textDecoration: "none",
                color: "inherit",
                margin: "5px",
                paddingTop:8
              }}
            >
              OfflinePlot
            </Link> */}
            

            <Link
              to="/logout"
              style={{
                textDecoration: "none",
                color: "inherit",
                margin: "5px",
              }}
            >
              Logout
              <IconButton>
                <LogoutIcon />
              </IconButton>
            </Link>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Bar;
