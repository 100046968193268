import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import AcUnitTwoToneIcon from '@mui/icons-material/AcUnitTwoTone';
import Divider from '@mui/material/Divider';

const style = {
  position: 'absolute',
  top: '30%',
  left: '30%',
  transform: 'translate(-20%, -30%)',
  width: "65rem",
  height: "30rem",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Loadcard = (props) => {
  const [selectedFile, setSelectedFile] = useState('');
  const fileOptions = props.unitdata.data.filelist;

  const handleFileChange = (event) => {
    setSelectedFile(event.target.value);
  };

  const handleDownload = () => {
    props.export_file(selectedFile)
    props.onClose();
  };

  const getLastPathComponent = (csn,filePath) => {
    const pathArray = filePath.split('/'+csn+'/');
    const lastComponent = pathArray[pathArray.length - 1];
    return lastComponent;
  };
  const handleExportunitdata = (csn,filelist) =>{
    props.setSnackopen(true);
    props.setSnackmsg("Generating ZIP in background....");
    props.export_data_retro(csn,filelist)
  };

  const handleExportFile = (param) => {
    props.export_file(param);
  };

  return (
    <Fade in={props.openLoadcard}>
      <Box sx={style}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AcUnitTwoToneIcon style={{ color: '#0b6bcb70', height: "3.0rem", width: "3.0rem", marginRight: "1rem" }}></AcUnitTwoToneIcon>
            <h1 style={{ color: '#0b6bcb', marginBottom: '20px' }}>{props.selectedCSN}</h1>
            <AcUnitTwoToneIcon style={{ color: '#0b6bcb70', height: "3.0rem", width: "3.0rem", marginLeft: "1rem" }}></AcUnitTwoToneIcon>
          </div>

          <div  style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem', marginBottom: '20px', width: '100%' }}>
            <Button startIcon={<DownloadIcon />} style={{ fontWeight: 800, borderRadius: "16px", backgroundColor: props.unitdata.data.rawfile === null ? "#e0e0e0" : "#0b6bcb", color: 'white', paddingTop: "25px", paddingBottom: "25px" }} variant="contained" color="primary" disabled={props.unitdata.data.rawfile === null} onClick={() => handleExportFile(props.unitdata.data.rawfile)} >
              Download Raw Field Data
            </Button>

            <Button startIcon={<DownloadIcon />} style={{ fontWeight: 800, borderRadius: "16px", backgroundColor: props.unitdata.data.l1cop === null ? "#e0e0e0" : "#0b6bcb", color: 'white', paddingTop: "25px", paddingBottom: "25px" }} variant="contained" color="primary" disabled={props.unitdata.data.l1cop === null} onClick={() => handleExportFile(props.unitdata.data.l1cop)}>
              Download L1 COP File
            </Button>

            <Button startIcon={<DownloadIcon />} style={{ fontWeight: 800, borderRadius: "16px", backgroundColor: props.unitdata.data.mappingfile === null ? "#e0e0e0" : "#0b6bcb", color: 'white', paddingTop: "25px", paddingBottom: "25px" }} variant="contained" color="primary" disabled={props.unitdata.data.mappingfile === null} onClick={() => handleExportFile(props.unitdata.data.mappingfile)}>
              Download Mappingfile
            </Button>
          </div>
          
          <Divider style={{color:'black', fontWeight:"500"}} > <span style={{color:'#00000038', fontWeight:"500"}} >--------------------------------------------------------------</span>Download Files<span style={{color:'#00000038', fontWeight:"500"}}
          >--------------------------------------------------------------</span></Divider>

          <FormControl style={{ minWidth: '200px', height: '50%', width: '70%', marginTop: '30px' }}>
            <InputLabel id="file-select-label">Select a File</InputLabel>
            <Select
              labelId="file-select-label"
              id="file-select"
              value={selectedFile}
              onChange={handleFileChange}
              label="Select a File"
            >
              {fileOptions.map((file, index) => (
                <MenuItem style={{ width: "100%" }} key={index} value={file}>
                  {getLastPathComponent(props.selectedCSN, file)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', width: '100%' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<DownloadIcon />}
              disabled={!selectedFile}
              onClick={handleDownload}
              style={{ borderRadius: "16px", paddingTop: "15px", paddingBottom: "15px", marginTop: '20px' }}
            >
              Download Selected File
            </Button>
            <Button startIcon={<DownloadIcon />} style={{borderRadius: "16px", marginLeft:'2rem', paddingTop: "15px", paddingBottom: "15px", marginTop: '20px' }} variant="contained" color="primary" disabled={fileOptions.length === 0} onClick={() => handleExportunitdata(props.selectedCSN,fileOptions)} >
              Download All
            </Button>

          </div>
        </div>
      </Box>
    </Fade>


  );
};

export default Loadcard;
