import React from "react";
import { logout } from "@carrier/auth-module";
import appConfig from "../config";

const Exit = () => {
  sessionStorage.removeItem("user");
  logout(appConfig.api);
  // return <div>Exit tata by by</div>;
};

export default Exit;
